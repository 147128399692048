export const useSocialStore = defineStore({
  id: 'social-store',
  state: () => {
    return {
      providers: [],
      provider: null,
      initiated: false,
      userStore: null,
      connected: {
        Discord: null,
        Twitter: null,
      },
    };
  },
  actions: {
    socialInit() {
      this.initiated = true;
      const websocketStore = useWebsocketStore();
      websocketStore.$onAction(({ name, args, }) => {
        if (name !== 'handleMessage') { return; }

        if (args[0].type === 'User:SocialLinkAdded') { useMessageHandler({ name, args, }, this.reloadLinks, 'User:SocialLinkAdded'); }

        if (args[0].type === 'User:SocialLinkUpdated') { useMessageHandler({ name, args, }, this.reloadLinks, 'User:SocialLinkUpdated'); }
      });
    },
    async getAuthUrl(provider) {
      const nuxtApp = useNuxtApp();

      try {
        this.provider = provider;
        const returnUrl = this.getCallbackUrl(provider);
        return await nuxtApp.$api(`/social/connect/auth/url/${provider}?returnUrl=${returnUrl}`, { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        this.provider = null;
        return '';
      }
    },
    async sendAuthResult(provider, data) {
      const name = this.toTitleCase(provider);
      const result = { provider: name, ...data, };
      const nuxtApp = useNuxtApp();

      try {
        await nuxtApp.$api('/social/connect/auth', {
          method: 'POST',
          body: result,
        }, 0);

        this.connected[provider] = true;
        return { success: true, errReason: '', };
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        this.provider = null;
        return { success: false, errReason: err.data?.message, };
      }
    },
    async getProviders() {
      const nuxtApp = useNuxtApp();

      try {
        this.providers = await nuxtApp.$api('/social/connect/providers/active', { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getLinks() {
      const nuxtApp = useNuxtApp();

      try {
        this.links = await nuxtApp.$api('/social/link', { method: 'GET', });
        for (const link of this.links) { this.connected[link.provider] = link; }
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async removeLink(provider) {
      const nuxtApp = useNuxtApp();

      try {
        this.provider = provider;
        await nuxtApp.$api(`/social/link/${provider}`, { method: 'DELETE', });
        await this.getLinks();
        this.connected[provider] = null;
        this.provider = null;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
        this.provider = null;
      }
    },
    async refreshMember(provider) {
      const nuxtApp = useNuxtApp();

      try {
        return await nuxtApp.$api(`/social/link/${provider}/member/refresh`, {
          method: 'PUT',
          body: {
            provider,
          },
        });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async repost(provider, postId) {
      return await useNuxtApp().$api(`/social/link/${provider}/repost/${postId}`, {
        method: 'POST',
      });
    },
    getCallbackUrl(provider) {
      return window.location.origin
        ? `${window.location.origin}/social/connect/${provider.toLowerCase()}/callback`
        : `${window.location.protocol}//${window.location.hostname}${window.location.port ? ':' + window.location.port : ''}`;
    },
    toTitleCase(provider) {
      return provider?.toLowerCase().replace(/\b\w/g, s => s.toUpperCase());
    },
    reloadLinks() {
      this.getLinks();
      this.provider = null;
    },
    reset() {
      this.provider = null;
    },
  },
});
