const DEFAULT_WALLET_PROVIDER_NAME = 'metamask';

const PROVIDER_NAME = {
  MetaMask: 'MetaMask',
  WalletConnect: 'WalletConnect',
  TrustWallet: 'Trust Wallet',
  Coinbase: 'Coinbase',
  Argent: 'Argent',
  Rainbow: 'Rainbow',
  Phantom: 'Phantom',
};

function mapProviderName(name) {
  if (!name) {
    return 'Name not provided. Please provide a name.';
  }
  switch (name.toLowerCase()) {
    case 'metamask':
      return PROVIDER_NAME.MetaMask;

    case 'coinbase':
      return PROVIDER_NAME.Coinbase;

    case 'walletconnect':
      return PROVIDER_NAME.WalletConnect;

    case 'rainbow':
    case '🌈 rainbow':
      return PROVIDER_NAME.Rainbow;

    case 'phantom':
      return PROVIDER_NAME.Phantom;

    default:
      return name;
  }
}

export { DEFAULT_WALLET_PROVIDER_NAME, PROVIDER_NAME, mapProviderName, };
