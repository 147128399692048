export const useNotificationsStore = defineStore({
  id: 'notifications-store',
  state: () => {
    const nuxtApp = useNuxtApp();

    return {
      notificationCount: 0,
      itemCount: 0,
      initiated: false,
      blockedRoutes: ['games-id', 'live-games-id',],
      pollUserGifts: nuxtApp.$config.public.POLL_USER_GIFTS,
      pollGiftsInterval: nuxtApp.$config.public.POLL_USER_GIFTS_INTERVAL_MS,
    };
  },
  getters: {
    notificationAvailable() {
      return !!this.notificationCount;
    },
    count() {
      return this.notificationCount + this.itemCount;
    },
  },
  actions: {
    initCountListener() {
      // Load notification count and set listeners to update when websocket msg arrives
      const websocketStore = useWebsocketStore();
      websocketStore.$onAction(({ name, args, }) => {
        if (name === 'handleMessage' && args[0].type === 'Notification:Added') {
          useMessageHandler({ name, args, }, this.getNotificationCount, 'Notification:Added', 'CatchAll');
        }
      });
      this.getNotificationCount();
      this.getItemCount();
      this.initiated = true;
    },
    async getNotificationCount() {
      const authStore = useAuthStore();
      if (!authStore.isUserLogged) { return; }
      const nuxtApp = useNuxtApp();
      try {
        const res = await nuxtApp.$api('/notification/count?read=false', { method: 'GET', });
        this.notificationCount = res.count || 0;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async getItemCount() {
      const authStore = useAuthStore();
      const uiStore = useUiStore();
      const nuxtApp = useNuxtApp();
      const tournamentStore = useTournamentStore();
      const minigameStore = useMinigamesStore();

      if (!authStore.isUserLogged) { return; }

      if (!this.initiated && this.pollUserGifts) {
        return setInterval(this.getItemCount, this.pollGiftsInterval);
      }

      // Routes that are blocked from showing the gift modal
      const blockedRoute = this.blockedRoutes.includes(nuxtApp.$router.currentRoute.value.name);

      // Prevent item modal from showing on blocked routes and while a game is in-play
      if (tournamentStore.tournamentSelected || minigameStore.gameSelected || blockedRoute || uiStore.showDepositModal || uiStore.showConfirmModal) { return; }

      try {
        const res = await nuxtApp.$api('/inventory/count?claimed=false&expired=false', { method: 'GET', });
        if (this.initiated && res.count > this.itemCount) {
          const data = (await nuxtApp.$api('/inventory', {
            method: 'GET',
            params: {
              page: 1,
              pageSize: 1,
              expired: false,
              claimed: false,
            },
          })).items[0];
          setTimeout(() => {
            uiStore.showInfoModal = true;
            uiStore.infoModalType = 'NewItem';
            uiStore.infoModalContent = data;
          }, 500);
        }

        this.itemCount = res.count || 0;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    reduceCount() {
      if (this.notificationCount > 0) { this.notificationCount--; }
    },
    setItemCount(value) {
      this.itemCount = value;
    },
    async markAsRead(id) {
      const nuxtApp = useNuxtApp();
      const url = `/notification/${id}/read`;
      try {
        await nuxtApp.$api(url, { method: 'POST', });
        this.reduceCount();
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
  },
});
