import 'chartiq/js/advanced';
import 'chartiq/js/addOns';
import getLicenseKey from 'chartiq/key';
import getDefaultConfig from 'chartiq/js/defaultConfiguration';
import PerfectScrollbar from 'chartiq/js/thirdparty/perfect-scrollbar.esm';
import { CIQ } from 'chartiq/js/components';
import dayjs from 'dayjs';
import uuid from 'uuid';

export default defineNuxtPlugin(() => {
  return {
    provide: {
      chartIQ: {
        CIQ,
        init() {
          getLicenseKey(CIQ);
          CIQ.suppressPackageInfo = true;
        },
        getConfig(options) {
          this.init();

          const config = getDefaultConfig({
            ...options,
            scrollStyle: PerfectScrollbar,
          });

          if (options.restore) {
            config.restore = options.restore;
          }

          config.enabledAddOns = {
            animation: true,
            inactivityTimer: false,
            rangeSlider: false,
            shortcuts: false,
            tableView: false,
            tooltip: false,
          };

          config.chartId = options.assetCode;
          config.initialSymbol = options.assetCode;
          config.onChartReady = options.onChartReady;

          if (options.setPropertiesForInterval) {
            const base = CIQ.ChartEngine.XAxis.setPropertiesForInterval;

            CIQ.ChartEngine.XAxis.setPropertiesForInterval = {
              tick: (label, record, market, stx) => {
                base.tick(label, record, market, stx);
                label.text = dayjs(record.DT).format('H:mm:ss:SSS');
              },
              millisecond: (label, record, market, stx) => {
                base.millisecond(label, record, market, stx);
                label.text = dayjs(record.DT).format('H:mm:ss');
              },
              second: (label, record, market, stx) => {
                base.second(label, record, market, stx);
                label.text = dayjs(record.DT).format('H:mm:ss');
              },
              minute: (label, record, market, stx) => {
                base.minute(label, record, market, stx);
                label.text = dayjs(record.DT).format('H:mm');
              },
              hour: (label, record, market, stx) => {
                base.hour(label, record, market, stx);
                label.text = dayjs(record.DT).format('H:mm');
              },
              day: (label, record, market, stx) => {
                base.day(label, record, market, stx);
                label.text = dayjs(record.DT).format('H:mm');
              },
              week: (label, record, market, stx) => {
                base.week(label, record, market, stx);
                label.text = dayjs(record.DT).format('MMM DD');
              },
              month: (label, record, market, stx) => {
                base.month(label, record, market, stx);
                label.text = dayjs(record.DT).format('MMM DD');
              },
              year: (label, record, market, stx) => {
                base.year(label, record, market, stx);
                label.text = dayjs(record.DT).format('MMM DD');
              },
            };
          }

          return config;
        },
        createChartAndUI(options) {
          const config = this.getConfig(options);
          const chart = new CIQ.UI.Chart();
          const context = chart.createChartAndUI({ config, });
          return context;
        },
        createMarker(stx, node, tick, yPositioner, xPositioner, xValue) {
          const marker = new CIQ.Marker({
            id: uuid(),
            stx,
            node,
            xPositioner,
            x: xValue,
            yPositioner,
            tick,
          });

          return marker;
        },
      },
    },
  };
});

CIQ.Animation = function(config) {
  if (!config) { throw new Error('Invalid constructor arguments.'); }
  let stx, animationParameters, easeMachine;
  if (config instanceof CIQ.ChartEngine) {
    // legacy constructor
    stx = arguments[0];
    animationParameters = arguments[1];
    easeMachine = arguments[2];
  } else {
    stx = config.stx;
    animationParameters = config.animationParameters;
    easeMachine = config.easeMachine;
  }
  if (!stx) {
    console.warn('No CIQ.ChartEngine provided. Cannot properly create CIQ.Animation instance');
    return;
  }
  this.cssRequired = true;
  const params = {
    stayPut: false,
    ticksFromEdgeOfScreen: 5,
    granularity: 1000000,
  };
  animationParameters = CIQ.extend(params, animationParameters);

  if (params.tension) { stx.chart.tension = animationParameters.tension; }
  stx.tickAnimator = easeMachine || new CIQ.EaseMachine('easeOutCubic', 1000);
  // var scrollAnimator = new CIQ.EaseMachine('easeInOutCubic', 1000);

  const flashingColors = ['#0298d3', '#19bcfc', '#5dcffc', '#9ee3ff',];
  const flashingColorThrottle = 20;
  let flashingColorIndex = 0;
  let flashingColorThrottleCounter = 0;
  let filterSession = false;
  let nextBoundary = null;

  function initMarketSessionFlags() {
    filterSession = false;
    nextBoundary = null;
  }

  if (CIQ.UI) {
    const listener = ({ value, }) => {
      if (params.tension) {
        stx.chart.tension = value ? animationParameters.tension : 0;
      }
    };
    CIQ.UI.observeProperty('animation', stx.layout, listener);
    stx.addEventListener('destroy', function() {
      CIQ.UI.unobserveProperty('animation', stx.layout, listener);
    });
  }

  stx.addEventListener(['symbolChange', 'layout',], function(obj) {
    initMarketSessionFlags();
  });

  stx.prepend('updateCurrentMarketData', function(data, chart, symbol, params) {
    if (!chart) { chart = this.chart; }
    if (params && params.fromTrade && chart.closePendingAnimation) {
      params.finalClose = chart.closePendingAnimation.Close;
    }
  });

  stx.prepend('updateChartData', function(appendQuotes, chart, params) {
    const self = this;
    if (!chart) {
      chart = self.chart;
    }
    if (
      !chart
      || !chart.defaultChartStyleConfig
      || chart.defaultChartStyleConfig === 'none'
      || !this.layout.animation
    ) {
      return;
    }

    if (params !== undefined && (params.animationEntry || params.secondarySeries)) {
      return;
    }

    if (!chart.dataSegment) { return; }

    function completeLastBar(record) {
      if (!chart.masterData) { return; }
      for (let md = chart.masterData.length - 1; md >= 0; md--) {
        const bar = chart.masterData[md];
        if (bar.Close || bar.Close === 0) {
          bar.Close = record.Close;
          if (record.LastSize) { bar.LastSize = record.LastSize; }
          if (record.LastTime) { bar.LastTime = record.LastTime; }
          // Reset properties to close if Open, High and Low have been added and changed during animation process
          if (record.Open === undefined && bar.Open !== undefined) {
            bar.Open = record.Close;
          }
          if (record.High === undefined && bar.High !== undefined) {
            bar.High = record.Close;
          }
          if (record.Low === undefined && bar.Low !== undefined) {
            bar.Low = record.Close;
          }

          self.updateCurrentMarketData(
            {
              Close: bar.Close,
              DT: bar.DT,
              LastSize: bar.LastSize,
              LastTime: bar.LastTime,
            },
            null,
            null,
            { animationLastBar: true, fromTrade: true, }
          );
          self.createDataSet(null, null, { appending: true, });
          return;
        }
      }
    }
    function unanimateScroll() {
      if (chart.animatingHorizontalScroll) {
        chart.animatingHorizontalScroll = false;
        self.micropixels = self.nextMicroPixels = self.previousMicroPixels; // <-- Reset self.nextMicroPixels here
        chart.lastTickOffset = 0;
      }
      if (chart.closePendingAnimation) {
        completeLastBar(chart.closePendingAnimation);
        chart.closePendingAnimation = null;
      }
    }
    const tickAnimator = self.tickAnimator;
    // These chart types are the only types supported by animation
    const supportedChartType = this.mainSeriesRenderer.supportsAnimation;
    if (supportedChartType) {
      if (!tickAnimator) {
        console.warn('Animation plug-in can not run because the tickAnimator has not been declared. See instructions in animation.js');
        return;
      }

      // If symbol changes then reset all of our variables
      if (this.prevSymbol !== chart.symbol) {
        this.prevQuote = 0;
        chart.closePendingAnimation = null;
        this.prevSymbol = chart.symbol;
      }
      unanimateScroll();
      tickAnimator.stop(stx.ownerWindow);

      if (appendQuotes.length > 1) {
        // Only the last quote can be be animated. First add all except the last without animation
        const quotesExceptLastOne = appendQuotes.splice(
          0,
          appendQuotes.length - 1
        );
        const firstSetParams = CIQ.clone(params);

        firstSetParams.animationEntry = true;
        firstSetParams.bypassGovernor = true;
        firstSetParams.noCreateDataSet = false;
        firstSetParams.appending = true;
        self.updateChartData(quotesExceptLastOne, chart, firstSetParams);
      }
    }
    let newParams = CIQ.clone(params);
    if (!newParams) { newParams = {}; }
    newParams.animationEntry = true;
    newParams.bypassGovernor = true;
    newParams.noCreateDataSet = false;
    newParams.appending = true;
    // newParams.allowReplaceOHL = true;
    newParams.firstLoop = true;
    const symbol = this.chart.symbol;
    const period = this.layout.periodicity;
    const interval = this.layout.interval;
    const timeUnit = this.layout.timeUnit;

    function cb(quote, prevQuote, chartJustAdvanced) {
      return function(newData) {
        if (
          !chart.dataSet.length
          || symbol !== chart.symbol
          || period !== self.layout.periodicity
          || interval !== self.layout.interval
          || timeUnit !== self.layout.timeUnit
        ) {
          // console.log ('---- STOP animating: Old',symbol,' New : ',chart.symbol, Date())
          tickAnimator.stop(self.ownerWindow);
          unanimateScroll();
          return; // changed symbols mid animation
        }
        const q = CIQ.clone(quote);
        q.Adj_Close = null; // Don't use this, it will mess up our calculated close
        // animationParameters.granularity <<------ IMPORTANT! Use 1000000 for small price increments, otherwise animation will be in increments of .0001
        q.Close = Math.round(newData.Close * animationParameters.granularity) / animationParameters.granularity;
        if (chart.animatingHorizontalScroll) {
          self.micropixels = newData.micropixels;
          chart.lastTickOffset = newData.lineOffset;
        }
        newParams.updateDataSegmentInPlace = !tickAnimator.hasCompleted;
        // console.log('animating: Old',symbol,' New : ',chart.symbol);
        const updateQuotes = [q,];
        // Don't include previous quote if tick mode. It will append, duplicating the quote
        if (chartJustAdvanced && self.layout.interval !== 'tick') {
          updateQuotes.unshift(prevQuote);
        }
        self.updateChartData(updateQuotes, chart, newParams);
        newParams.firstLoop = false;
        if (tickAnimator.hasCompleted) {
          // console.log( 'animator has completed') ;
          // self.pendingScrollAdvance=false;
          // var possibleYAxisChange = chart.animatingHorizontalScroll;
          unanimateScroll();
          /*
            if (possibleYAxisChange) { // <---- Logic no longer necessary
            // After completion, one more draw for good measure in case our
            // displayed high and low have changed, which would trigger
            // the y-axis animation
            setTimeout(function(){
              self.draw();
              }, 0);
            }
          */
        }
      };
    }
    if (supportedChartType) {
      const quote = appendQuotes[appendQuotes.length - 1];
      this.prevQuote = this.currentQuote('Close'); // <---- prevQuote logic has been changed to prevent forward/back jitter when more than one tick comes in between animations
      let chartJustAdvanced = false; // When advancing, we need special logic to deal with the open
      let dontScroll = false;
      if (!self.isHome()) {
        dontScroll = true;
      }
      if (!quote || !quote.Close || !this.prevQuote || !this.prevQuote.Close) {
        return false;
      }

      if (this.extendedHours && chart.market.market_def) {
        // Filter out unwanted sessions
        const dtToFilter = quote.DT;
        if (CIQ.ChartEngine.isDailyInterval(interval)) {
          filterSession = !chart.market.isMarketDate(dtToFilter);
        } else if (!nextBoundary || nextBoundary <= dtToFilter) {
          const session = chart.market.getSession(dtToFilter);
          filterSession = session !== '' && (!this.layout.marketSessions || !this.layout.marketSessions[session]);
          nextBoundary = chart.market[filterSession ? 'getNextOpen' : 'getNextClose'](dtToFilter);
        }
        if (filterSession) {
          this.draw();
          return false;
        }
      }

      let barSpan = period;
      if (interval === 'second' || timeUnit === 'second') {
        barSpan *= 1000;
      } else if (interval === 'minute' || timeUnit === 'minute') {
        barSpan *= 60000;
      }

      if (!isNaN(interval)) { barSpan *= interval; }
      if (interval === 'day' || timeUnit === 'day') {
        chartJustAdvanced = quote.DT.getDate() !== this.prevQuote.DT.getDate();
      } else if (interval === 'week' || timeUnit === 'week') {
        chartJustAdvanced = quote.DT.getDate() >= this.prevQuote.DT.getDate() + 7;
      } else if (interval === 'month' || timeUnit === 'month') {
        chartJustAdvanced = quote.DT.getMonth() !== this.prevQuote.DT.getMonth();
      } else {
        chartJustAdvanced = quote.DT.getTime() >= this.prevQuote.DT.getTime() + barSpan;
      }

      const linearChart = !this.mainSeriesRenderer.standaloneBars;

      let beginningOffset = 0;
      if (chartJustAdvanced) {
        if (this.animations.zoom.hasCompleted) {
          const candleWidth = this.layout.candleWidth;
          if (chart.scroll <= chart.maxTicks) {
            while (this.micropixels > 0) {
              // If micropixels is larger than a candle then scroll back further
              chart.scroll++;
              this.micropixels -= candleWidth;
            }
          }
          beginningOffset = candleWidth * -1;
          if (chart.scroll <= chart.maxTicks) {
            this.previousMicroPixels = this.micropixels;
            this.nextMicroPixels = this.micropixels + candleWidth;
            if (
              chart.dataSegment.length < chart.maxTicks - animationParameters.ticksFromEdgeOfScreen
              && !animationParameters.stayPut
            ) {
              this.nextMicroPixels = this.micropixels;

              if (
                chart.scroll
                >= (chart.maxTicks - this.preferences.whitespace / this.layout.candleWidth)
              ) {
                chart.scroll++;
              }
            }
            chart.animatingHorizontalScroll = linearChart; // When the chart advances we also animate the horizontal scroll by incrementing micropixels
            chart.previousDataSetLength = chart.dataSet.length;
          } else if (!dontScroll) {
            chart.scroll++;
          }
        } else {
          return false;
        }
      }
      chart.closePendingAnimation = {
        Close: quote.Close,
        Open: quote.Open,
        High: quote.High,
        Low: quote.Low,
      };

      const start = chartJustAdvanced && !linearChart ? quote.Open : this.prevQuote.Close;
      tickAnimator.run(
        cb(quote, CIQ.clone(this.prevQuote), chartJustAdvanced),
        {
          Close: start,
          micropixels: this.nextMicroPixels,
          lineOffset: beginningOffset,
        },
        { Close: quote.Close, micropixels: this.micropixels, lineOffset: 0, },
        false,
        stx.ownerWindow
      );
      return true; // bypass default behavior in favor of animation
    }
  });

  stx.append('draw', function() {
    if (filterSession) { return; }
    if (
      this.chart.dataSet
      && this.chart.dataSet.length
      && this.mainSeriesRenderer.supportsAnimation
      && this.layout.animation
    ) {
      if (flashingColorThrottleCounter % flashingColorThrottle === 0) {
        flashingColorIndex++;
        flashingColorThrottleCounter = 0;
      }
      flashingColorThrottleCounter++;

      const currentQuote = this.currentQuote('Close');
      if (!currentQuote) { return; }
      const price = currentQuote.Close;
      let x = this.pixelFromTick(currentQuote.tick, this.chart);
      if (this.chart.lastTickOffset) { x = x + this.chart.lastTickOffset; }
      const y = this.pixelFromPrice(price, this.chart.panel);
      if (
        this.chart.yAxis.left > x
        && this.chart.yAxis.top <= y
        && this.chart.yAxis.bottom >= y
      ) {
        if (flashingColorIndex >= flashingColors.length) {
          flashingColorIndex = 0;
        }
        this.chart.context.beginPath();
        this.chart.context.moveTo(x, y);
        this.chart.context.arc(
          x,
          y,
          2 + flashingColorIndex * 1.07,
          0,
          Math.PI * 2,
          false
        );
        this.chart.context.fillStyle = flashingColors[flashingColorIndex];
        this.chart.context.fill();
      }
    }
  });
};
