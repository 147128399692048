// Functions and state changes required for logged in user browsing the platform
export async function usePlatformStarter() {
  const authStore = useAuthStore();
  const {
    isUserLogged,
  } = storeToRefs(authStore);
  const userStore = useUserStore();
  const bankingStore = useBankingStore();
  const cryptoStore = useCryptoStore();
  const diamondsStore = useDiamondsStore();
  const websocketStore = useWebsocketStore();
  const notificationsStore = useNotificationsStore();
  const socialStore = useSocialStore();
  const tradingStore = useTradingStore();
  const rewardStore = useRewardStore();

  if (!isUserLogged.value) { return; }

  // Individual checks
  if (userStore.userData === null) {
    userStore.userInit();
    await userStore.getUserData();
  }

  if (!cryptoStore.initiated) {
    cryptoStore.cryptoInit();
  }

  if (!diamondsStore.initiated) {
    diamondsStore.diamondsInit();
  }

  // Login to websocket
  if (websocketStore.ws && !websocketStore.loggedin) {
    websocketStore.websocketLogin();
  }

  if (websocketStore.ws && !bankingStore.initiated) {
    bankingStore.bankingInit();
  }

  if (websocketStore.ws && !socialStore.initiated) {
    socialStore.socialInit();
  }

  if (websocketStore.ws && !tradingStore.initiated) {
    tradingStore.init();
  }

  // Start notification service
  if (!notificationsStore.initiated) {
    notificationsStore.initCountListener();
  }

  rewardStore.init();
}
