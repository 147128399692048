import { useRouter } from 'vue-router';

import { Log } from '@/core/logger';

export function usePostMessage() {
  const authStore = useAuthStore();
  const { completeLogin, } = authStore;

  const uiStore = useUiStore();
  const { toastNotification, showConnectModal, activeGameProviderRef, replayModalUrl, } = storeToRefs(uiStore);

  const router = useRouter();
  const { $gtmCustomEvent, } = useNuxtApp();

  onMounted(() => {
    window.addEventListener('message', (e) => {
      onMessageReceived(e);
    });
  });

  async function onMessageReceived(event) {
    if (activeGameProviderRef.value && event.data[activeGameProviderRef.value]) {
      const { type, url = null, } = event.data[activeGameProviderRef.value];
      if (type === 'OPEN_WINDOW' && url) {
        replayModalUrl.value = url;
        return;
      }
      if (type === 'GAME_CLOSED' && replayModalUrl.value) {
        replayModalUrl.value = null;
        return;
      }
      if (type === 'GAME_CLOSED') {
        await navigateTo('/games');
        return;
      }
    }
    if (event.origin !== window.origin) {
      return;
    }

    switch (event?.data?.action) {
      case 'authComplete':
        await onAuthComplete(event.data);
        break;
      case 'authError':
        onAuthError(event.data);
        break;
    }
  }

  async function onAuthComplete(data) {
    Log.log('🔒 Auth Complete...', data);
    const firstLogin = localStorage.getItem('SocialFirstLogin') === 'true';

    if (firstLogin) {
      $gtmCustomEvent({ event: 'sign_up', action: 'success', social: true, });
      await completeLogin(true, true);
      router.push('/');
      return;
    }

    await completeLogin(false, true);
  }

  function onAuthError(data) {
    showConnectModal.value = false;
    router.push('/');
    toastNotification.value = {
      type: 'error',
      title: data.title,
      content: data.message,
      closeAfter: 5000,
    };

    if (data.error) { Log.error('🔒 Auth Error...', data.error); }
  }
}
