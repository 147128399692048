// Global middleware - triggeres on every page load. Also look in plugins/hooks.client.js
export default defineNuxtRouteMiddleware(async(to, from) => {
  const { $removeTrailingSlash, } = useNuxtApp();
  const pathTo = $removeTrailingSlash(to.path);

  // Conditions to stop execution of this middleware
  if (pathTo === '/maintenance' || pathTo === '/auth') { return; }

  const authStore = useAuthStore();
  const uiStore = useUiStore();
  const referralStore = useReferralStore();

  // Check for breakouts from signup process flow
  if (from?.meta?.middleware === 'route-guard-signup') {
    authStore.firstLogin = false;
  }

  // Referral checks
  await referralStore.init(to);

  // Use hash to display UI elements (ie. video modal)
  if (to.hash) {
    uiStore.hashAction(to.hash);
  }
});
