export default defineNuxtPlugin((nuxtApp) => {
  const uiStore = useUiStore();
  const route = useRoute();
  const authStore = useAuthStore();

  nuxtApp.hook('app:mounted', () => {
    setTimeout(async() => {
      let welcomeModalHide = localStorage.getItem('welcomeModalHide3') === 'true';
      if (authStore.isUserLogged || uiStore.showConnectModal) {
        welcomeModalHide = true;
      }
      const welcomePromoModalHide = localStorage.getItem('welcomePromo150ethModalHide4') === 'true';

      if (route?.meta?.hideWelcomeModal || route.query.hidePopup) {
        uiStore.showInfoModal = false;
        return;
      }

      // Read stats as they're needed for all welcome modals
      if (!welcomePromoModalHide || !welcomeModalHide) {
        uiStore.infoModalStats = await nuxtApp.$api('/statistics/total', { method: 'GET', });
      } else {
        uiStore.showInfoModal = false;
        return;
      }

      // Read promo modal content from Sanity
      if (!welcomePromoModalHide && uiStore.infoModalStats) {
        try {
          const query = `*[_type == "info-modals" && modalId == "WelcomePromo"] {
            _id,
            heading,
            modalId,
          }[0]
          `;
          const cmsData = await nuxtApp.$sanity.fetch(query);
          if (cmsData?.heading) {
            uiStore.infoModalStats.cmsData = cmsData;
          }
        } catch (err) {
          nuxtApp.$rollbar.error('method failed', err);
        }
      }

      // Determine which modal to show
      if (!welcomePromoModalHide && uiStore.infoModalStats && uiStore.infoModalStats.cmsData) {
        uiStore.infoModalType = 'WelcomePromo150eth';
        uiStore.showInfoModal = true;
      } else if (!welcomeModalHide && uiStore.infoModalStats) {
        uiStore.infoModalType = 'Welcome';
        uiStore.showInfoModal = true;
      }
    }, 1000);
  });
});
