export const useRewardStore = defineStore('rewardStore', () => {
  const initiated = ref(false);
  const pendingRewards = ref([]);
  const inventoryId = ref(null);
  const processingClaim = ref(false);
  const salonRougeRewardStatus = ref('pending');
  const vipTransferRewardInfo = ref([]);

  const websocket = useWebsocketStore();
  const { subscribe, unsubscribe, } = websocket;

  const userStore = useUserStore();
  const { getUserData, } = userStore;

  const nuxtApp = useNuxtApp();
  const { $api, $rollbar, } = nuxtApp;

  async function init() {
    subscribe('Reward');

    websocket.$onAction(({ name, args, }) => {
      if (name === 'handleMessage') {
        if (args[0].type === 'Reward:Added') { useMessageHandler({ name, args, }, onRewardAdded, 'Reward:Added'); }
        if (args[0].type === 'Reward:Updated') { useMessageHandler({ name, args, }, onRewardUpdated, 'Reward:Updated'); }
      }
    });

    await fetchPendingRewards();
    await getVipTransferInfo();
    initiated.value = true;
  }

  function onRewardAdded(reward) {
    if (reward.status === 'Pending') {
      pendingRewards.value.unshift(reward);
    }
  }

  async function onRewardUpdated(update) {
    const reward = pendingRewards.value.find(r => r.id === update.id);

    if (!reward) {
      return;
    }

    if (update.status !== 'Pending') {
      pendingRewards.value.splice(pendingRewards.value.indexOf(reward), 1);

      // Waiting for claimed status to come back to update ui
      if (update.status === 'Claimed') {
        await fetchPendingRewards();
        processingClaim.value = false;
        unsubscribe('Reward');
      }
      return;
    }
    Object.assign(reward, update);
  }

  function unsubscribeRewardsSocket() {
    unsubscribe('Reward');
  }

  async function fetchPendingRewards() {
    try {
      pendingRewards.value = await $api('/reward/recent',
        {
          method: 'GET',
          params: {
            templateIdentifier: 'WagerTarget',
            limit: 1,
          },

        }
      );

      if (pendingRewards.value[0].status === 'Cancelled' || pendingRewards.value[0].status === 'Failed') {
        pendingRewards.value = [];
        return;
      }

      salonRougeRewardStatus.value = pendingRewards.value[0].status;

      if (salonRougeRewardStatus.value === 'Completed' || salonRougeRewardStatus.value === 'Claimed') {
        inventoryId.value = pendingRewards.value[0]?.inventoryItemId;
      }
    } catch (err) {
      $rollbar.error('method failed', err);
    }
  }

  async function claimBonusReward() {
    try {
      processingClaim.value = true;
      const response = await $api(`inventory/${inventoryId.value}/claim`, {
        method: 'POST',
      });

      await getUserData();
      return response;
    } catch (err) {
      console.log('claimBonusReward error:::', err);
    }
  }

  async function getVipTransferInfo() {
    try {
      vipTransferRewardInfo.value = await $api('/reward/recent', {
        method: 'GET',
        params: {
          status: 'Pending',
          templateIdentifier: 'KYFREE',
          limit: 1,
        },
      });
    } catch (err) {
      console.log('getVipTransferReward error:::', err);
    }
  }

  const isVipTransferPending = computed(() => !!vipTransferRewardInfo.value.length);

  function reset() {
    initiated.value = false;
    pendingRewards.value = [];
    inventoryId.value = null;
    processingClaim.value = false;
    salonRougeRewardStatus.value = 'pending';
    vipTransferRewardInfo.value = [];
    unsubscribeRewardsSocket();
  }

  return {
    init,
    claimBonusReward,
    fetchPendingRewards,
    onRewardUpdated,
    onRewardAdded,
    unsubscribeRewardsSocket,
    pendingRewards,
    processingClaim,
    salonRougeRewardStatus,
    inventoryId,
    initiated,
    reset,
    isVipTransferPending,
    getVipTransferInfo,
  };
});
