import { EventEmitter } from 'events';
import CoinbaseWallet from '@coinbase/wallet-sdk';
import Web3 from 'web3';
import BigNumber from 'bignumber.js';

import { Log } from '@/core/logger';
import { WalletError } from '@/core/wallet/error';
import { PROVIDER_NAME } from '@/core/wallet/mapProviderName';
import { getConfig } from '@/utils/getConfig';

export class CoinbaseProvider {
  constructor() {
    this.events = new EventEmitter();
    this.name = PROVIDER_NAME.Coinbase;
    this.onboarding = undefined;
    this.provider = undefined;
    this.address = undefined;
    this.accounts = undefined;
    this.connected = false;
    this.chainId = undefined;
    this.initialised = false;
    this.peerName = undefined;
    this.modal = {
      signature: false,
      transaction: false,
    };
    this.network = 'Ethereum';
  }

  async init() {
    if (this.initialised) { return; }

    const coinbaseWallet = new CoinbaseWallet({
      appName: 'MetaWin',
      appLogoUrl: '~/assets/img/logos/metawin-icon-white.png',
      darkMode: true,
    });

    const rpcUrl = getConfig('ALCHEMY_ETHEREUM_ENDPOINT');
    const chainId = Web3.utils.hexToNumber(getConfig('ETHEREUM_NETWORK_ID'));

    const provider = coinbaseWallet.makeWeb3Provider(rpcUrl, chainId);

    Log.log('🪙 Coinbase Init...');
    this.initialised = true;

    provider.on('accountsChanged', (accounts) => {
      Log.debug('🤖 Coinbase Accounts Changed');
      this.accounts = accounts;
      this.address = accounts[0];
      this.events.emit('accountsChanged', accounts);
    });

    provider.on('chainChanged', (chainId) => {
      Log.debug('🤖 Coinbase Chain Changed');
      this.chainId = chainId;
      this.events.emit('chainChanged', chainId);
    });

    provider.on('connect', () => {
      Log.debug('🤖 Coinbase Connected');
      this.connected = true;
      this.events.emit('connect');
    });

    provider.on('disconnect', () => {
      Log.debug('🤖 Coinbase Disconnected');
      this.connected = false;
      this.events.emit('disconnect');
    });

    try {
      this.chainId = await provider.request({ method: 'eth_chainId', });
    } catch (err) {
      Log.error('Failed Retrieving Network ID', err);
    }

    try {
      this.accounts = await provider.request({ method: 'eth_accounts', });
    } catch (err) {
      Log.error('Failed Retrieving Accounts', err);
    }

    this.address = this.accounts?.length > 0
      ? this.accounts[0]
      : undefined;

    this.connected = provider.isConnected();
    this.provider = provider;
    Log.log('🪙 Coinbase Initialised');
  }

  async connect() {
    Log.log('🪙 Coinbase Connecting...');

    if (this.onboarding) { return; }

    if (!this.provider) { throw new Error('Coinbase provider not set'); }

    try {
      this.accounts = await this.provider.request({ method: 'eth_requestAccounts', });
    } catch (err) {
      Log.error('Failed Retrieving Accounts', err);
      throw this.parseError(err);
    }

    if (!this.accounts || this.accounts.length === 0) {
      Log.error('🪙 Coinbase Accounts Not Found');
      return;
    }

    if (!this.accounts[0]) {
      Log.error('🪙 Coinbase Account Not Found');
      return;
    }

    this.address = this.accounts[0];
    this.connected = this.provider.isConnected();

    Log.log('🪙 Coinbase Connected');
    return this.address;
  }

  disconnect() {
    Log.log('🪙 Coinbase Disconnecting...');
    this.initialised = false;
  }

  async sign(address, messageToSign) {
    Log.log('🪙 Coinbase Signing...');

    try {
      return await this.provider.request({ method: 'personal_sign', params: [messageToSign, address,], });
    } catch (err) {
      throw this.parseError(err);
    }
  }

  onboard() {
    Log.log('🪙 Coinbase Onboarding...');

    if (!this.onboarding) { throw new Error('Coinbase onboarding not set'); }

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      window.open(`https://go.cb-w.com/dapp?cb_url=${window.location.toString().replace(/http(s?):\/\//, '')}`);
    } else {
      this.onboarding.startOnboarding();
    }
  }

  async getBalance(address) {
    const web3 = new Web3(this.provider);
    const balance = await web3.eth.getBalance(address);
    return Web3.utils.fromWei(balance);
  }

  async getTokenBalance(tokenAddress) {
    const web3 = new Web3(this.provider);
    const contract = new web3.eth.Contract([
      {
        constant: true,
        inputs: [{ name: '_owner', type: 'address', },],
        name: 'balanceOf',
        outputs: [{ name: 'balance', type: 'uint256', },],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ name: '', type: 'uint8', },],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
    ], tokenAddress);

    const decimals = await contract.methods.decimals().call();
    const rawBalance = await contract.methods.balanceOf(this.address).call();

    const balance = new BigNumber(rawBalance).shiftedBy(-decimals).toString();

    return balance;
  }

  async depositTokenAmount(tokenAddress, amount, network) {
    const depositAddress = useRuntimeConfig().public.ETHEREUM_TO;

    const web3 = new Web3(this.provider);

    const contract = new web3.eth.Contract([
      {
        constant: false,
        inputs: [{ name: '_to', type: 'address', }, { name: '_value', type: 'uint256', },],
        name: 'transfer',
        outputs: [{ name: '', type: 'bool', },],
        payable: false,
        stateMutability: 'nonpayable',
        type: 'function',
      },
      {
        constant: true,
        inputs: [],
        name: 'decimals',
        outputs: [{ name: '', type: 'uint8', },],
        payable: false,
        stateMutability: 'view',
        type: 'function',
      },
    ], tokenAddress);

    const decimals = await contract.methods.decimals().call();
    const weiAmount = Number(decimals) === 18 ? Web3.utils.toWei(amount) : new BigNumber(amount).shiftedBy(Number(decimals)).toString();

    return new Promise((resolve, reject) => {
      return contract.methods.transfer(depositAddress, weiAmount).send({ from: this.address, })
      .once('transactionHash', hash => resolve(hash))
      .catch(err => reject(err));
    });
  }

  async checkChain(network) {
    try {
      if (!this.provider) { throw new Error('Coinbase provider not set'); }

      let requiredChainId = getConfig('ETHEREUM_NETWORK_ID');

      if (network) {
        if (typeof network === 'number') { requiredChainId = Web3.utils.numberToHex(network); } else { requiredChainId = getConfig(`${network.toUpperCase()}_NETWORK_ID`); }

        if (!requiredChainId) { throw new Error(`Network ${network} not found`); }
      }

      const chainId = await this.provider.request({ method: 'eth_chainId', });
      Log.log(`Checking current chain is ${requiredChainId}...`);

      if (chainId === requiredChainId) {
        Log.log(`Current chain is ${requiredChainId}`);
        return;
      }

      const { getNetworkChainConfig, } = useBlockchainConfigStore();
      const networkConfig = getNetworkChainConfig(network);

      if (networkConfig && network !== 'Ethereum') { await this.provider.request({ method: 'wallet_addEthereumChain', params: [networkConfig,], }); }

      Log.log(`Current chain ${chainId} is not ${requiredChainId}, requesting switch...`);
      await this.provider.request({ method: 'wallet_switchEthereumChain', params: [{ chainId: requiredChainId, },], });
      this.chainId = requiredChainId;
    } catch (err) {
      Log.error('Chain Switch Failed', err);
      throw err;
    }
  }

  async startTransaction(dataOrAmount, network) {
    const envVars = useRuntimeConfig().public;

    await this.checkChain(network);

    let data = dataOrAmount;

    if (typeof dataOrAmount === 'string' || typeof dataOrAmount === 'number') {
      const wei = Web3.utils.toWei(dataOrAmount.toString());
      data = {
        from: Web3.utils.toChecksumAddress(this.address),
        to: envVars.ETHEREUM_TO,
        value: Web3.utils.toHex(wei),
        data: '0x',
      };
    }

    const txHash = await this.provider.request({
      method: 'eth_sendTransaction',
      params: [data,],
    });

    return txHash;
  }

  parseError(err) {
    if (!err.code) { return err; }

    switch (err.code) {
      case 4001:
        return new WalletError('The connection request was rejected.', err.code);

      default:
        return new WalletError(err.message, err.code);
    }
  }
}
