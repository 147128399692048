export const useDiamondsStore = defineStore({
  id: 'diamonds-store',
  state: () => {
    return {
      initiated: false,
      items: [],
      lastPurchases: [],
    };
  },
  getters: {
    /**
     * NB: Not currently used
     */
    lastDiamondPurchase(state) {
      if (!state.lastPurchases || !state.lastPurchases.length) { return null; }
      return state.lastPurchases.find(o => o.type === 'Diamonds');
    },
  },
  actions: {
    diamondsInit() {
      this.initiated = true;
      this.loadTicketStore();
    },
    async loadTicketStore() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/store', { method: 'GET', });
        this.items = data.items.sort((a, b) => a.price - b.price);
        this.lastPurchases = data.lastPurchases;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
  },
});
