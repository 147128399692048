<template>
  <div class="error-page">
    <Head>
      <Title>Error | MetaWin</Title>
      <Meta name="robots" content="noindex, nofollow"/>
      <Meta name="prerender-status-code" content="404"/>
    </Head>

    <ClientOnly>
      <div class="min-h-screen flex flex-col">
        <main class="pt-32 pb-20 px-3 flex items-center justify-center flex-col grow">
          <template v-if="!redirecting">
            <h1 class="text-3xl text-center">
              Something went wrong...
            </h1>

            <ButtonLink
              to="/"
              class="mt-6 inline-block"
            >
              Go to homepage
            </ButtonLink>
          </template>
          <template v-else>
            <h1 class="text-3xl text-center">
              Loading...
            </h1>
          </template>
        </main>

        <FooterMain/>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
const uiStore = useUiStore();
const {
  redirecting,
} = storeToRefs(uiStore);

onMounted(() => {
  nextTick(() => {
    document.body.classList.add('ready');
  });
});
</script>
