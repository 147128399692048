import BigNumber from 'bignumber.js';

export const useTournamentStore = defineStore({
  id: 'tournament-store',
  state: () => {
    return {
      tournaments: [],
      jackpots: [],
      allWins: [],
      lastWins: [],
      staticWins: [],
      jackpotWins: [],
      bigWins: [],
      tournamentSelected: null,
    };
  },
  getters: {
    /**
     * NB: Not currently used
     */
    groupedTournaments: (state) => {
      const grouped = state.tournaments.reduce((r, a) => {
        let group = r.find(g => (g.group && g.group === a.group) || g.templateId === a.templateId);
        if (!group) {
          group = {
            templateId: a.templateId,
            group: a.group,
            tournament: a,
          };
          r.push(group);
        }
        return r;
      }, []);

      // sort by level and template ID
      grouped.sort((a, b) => a.tournament.minLevel - b.tournament.minLevel || b.tournament.displayPriority - a.tournament.displayPriority || a.group - b.group || a.templateId - b.templateId);
      return grouped;
    },
  },
  actions: {
    async loadTournaments() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/tournament', { method: 'GET', });
        const dateTimeNow = Math.ceil(new Date().getTime() / 1000);
        const sortedTournaments = data
        .map(el => ({
          ...el,
          secondsToStart: Math.ceil(new Date(el.startTime).getTime() / 1000),
          secondsToEnd: Math.ceil(new Date(el.endTime).getTime() / 1000),
        }))
        .filter(el => (el.allowJoinAfterStart && (!el.secondsToEnd || (el.secondsToEnd - dateTimeNow) > 0)) || (!el.allowJoinAfterStart && (el.playerJoined || ((el.secondsToStart - dateTimeNow) > 0))))
        .sort((a, b) => ((a.secondsToStart - b.secondsToStart) || (a.id - b.id)));
        this.tournaments = sortedTournaments;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    /**
     * NB: Not currently used
     */
    async loadJackpots() {
      const nuxtApp = useNuxtApp();
      try {
        this.jackpots = await nuxtApp.$api('/tournament/jackpot', { method: 'GET', });
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    getJackpotById(id) {
      const item = this.jackpots.find(jackpot => jackpot.id === id);
      return item;
    },
    setTournamentSelected(tournament) {
      this.tournamentSelected = tournament;
    },
    checkHasCost(tournament) {
      const entrycost = typeof tournament.playerEntryCost === 'string' ? parseFloat(tournament.playerEntryCost) : tournament.playerEntryCost;
      return entrycost && (!tournament.playerJoined || tournament.playerAllocations === tournament.playerAllocationsComplete);
    },
    checkHasValidCutOffTime(tournament) {
      const nuxtApp = useNuxtApp();
      if (!tournament.entryCutOffTime) { return true; }
      const tournamentCutOffTime = nuxtApp.$dayjs(tournament.entryCutOffTime);
      const nowTime = nuxtApp.$dayjs.utc();
      return tournamentCutOffTime.diff(nowTime, 'seconds') > 0;
    },
    goToTournament(tournament, skipEnterModal = true) {
      const router = useRouter();
      const bankingStore = useBankingStore();
      const authStore = useAuthStore();
      const uiStore = useUiStore();

      if (!authStore.isUserLogged) {
        uiStore.openConnectModal({
          noLogin: true,
          message: 'Experience winning in Web3 by connecting your wallet',
        });
      }

      const hasCost = this.checkHasCost(tournament);
      const hasValidCutOffTime = this.checkHasValidCutOffTime(tournament);
      const balance = tournament.entryCostType === 'Cash' ? new BigNumber(bankingStore.balance) : bankingStore.diamondBalance;

      if (tournament.game.demoMode) {
        router.push(`/play/${tournament.id}`);
      } else if (hasCost && (tournament.playerEntryCost).isGreaterThan(balance)) {
        // tournament has cost and user doesnt have enough diamonds
        this.setTournamentSelected(tournament);
        this.depositModalVariant = 'depositToPlay';
        this.showDepositModal = true;
      } else if ((hasValidCutOffTime || tournament.playerJoined) && this.userLevel >= tournament.minLevel) {
        // no cost OR user has enough diamonds
        if (skipEnterModal || !hasCost) {
          // no cost OR user coming from diamond store => skips confirm dialog
          this.setTournamentSelected(null);
          router.push(`/play/${tournament.id}`);
        } else {
          // has cost AND user has enough diamonds => show confirm dialog
          this.setTournamentSelected(tournament);
          uiStore.tournamentEnterModalData = { reEnter: tournament.playerAllocations > 0, };
          uiStore.showTournamentEnterModal = true;
        }
      }
    },
  },
});
