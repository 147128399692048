import { EventEmitter } from 'events';
import bs58 from 'bs58';
import { Connection, PublicKey, LAMPORTS_PER_SOL, SystemProgram, TransactionMessage, VersionedTransaction } from '@solana/web3.js';
import { createAssociatedTokenAccountInstruction, createTransferInstruction, getAssociatedTokenAddress } from '@solana/spl-token';
import BigNumber from 'bignumber.js';

import { Log } from '@/core/logger';
import { WalletError } from '@/core/wallet/error';
import { PROVIDER_NAME } from '@/core/wallet/mapProviderName';
import { getConfig } from '@/utils/getConfig';

export class PhantomProvider {
  constructor() {
    this.events = new EventEmitter();
    this.name = PROVIDER_NAME.Phantom;
    this.onboarding = undefined;
    this.provider = undefined;
    this.address = undefined;
    this.accounts = undefined;
    this.connected = false;
    this.chainId = 'Solana';
    this.initialised = false;
    this.peerName = undefined;
    this.connection = new Connection(getConfig('ALCHEMY_SOLANA_ENDPOINT'));
    this.modal = {
      signature: false,
      transaction: false,
    };
    this.network = 'Solana';
  }

  async init() {
    if (this.initialised) {
      return;
    }

    Log.log('🟣 Phantom Init...');

    const isPhantomInstalled = window.phantom?.solana?.isPhantom;
    Log.log('isPhantomInstalled', isPhantomInstalled);

    if (!isPhantomInstalled) { window.open('https://phantom.app/', '_blank'); }

    this.provider = window.phantom?.solana;

    Log.log('this.provider', this.provider);

    this.provider.on('accountChanged', (publicKey) => {
      Log.debug('🤖 Phantom Account Changed');
      const address = publicKey?.toString();
      this.accounts = [address,];
      this.address = address;
      Log.debug('🤖 Phantom Account Changed To', address);
      this.events.emit('accountsChanged', this.accounts);
    });

    this.provider.on('connect', () => {
      Log.debug('🤖 Phantom Connected');
      Log.debug('connected', this.provider.publicKey.toString());
      this.connected = true;
      this.events.emit('connect');
    });

    this.provider.on('disconnect', () => {
      Log.debug('🤖 Phantom Disconnected');
      this.connected = false;
      this.events.emit('disconnect');
    });

    const resp = await this.provider.connect();
    this.address = resp.publicKey.toString();
    this.accounts = [this.address,];
    this.connected = this.provider.isConnected;
    this.initialised = true;
    Log.log('🟣 Phantom Initialised');
  }

  async connect() {
    Log.log('🟣 Phantom Connecting...', this.onboarding);

    if (this.onboarding) {
      return;
    }

    if (!this.provider) {
      throw new Error('Phantom provider not set');
    }

    const resp = await this.provider.connect();
    this.address = resp.publicKey.toString();
    this.connected = this.provider.isConnected;

    Log.log('🟣 Phantom Connected');
    return this.address;
  }

  async disconnect() {
    Log.log('🟣 Phantom Disconnecting...');
    await this.provider.disconnect();
    this.initialised = false;
  }

  async sign(_address, messageToSign) {
    Log.log('🟣 Phantom Signing...');

    try {
      const messageBytes = new TextEncoder().encode(messageToSign);
      const { signature, } = await this.provider.signMessage(
        messageBytes,
        'utf8'
      );
      return bs58.encode(signature);
    } catch (err) {
      throw this.parseError(err);
    }
  }

  onboard() {
    Log.log('🟣 Phantom Onboarding...');
    window.open('https://phantom.app/', '_blank');
  }

  async getBalance(address) {
    const pubKey = new PublicKey(address);
    const balance = await this.connection.getBalance(pubKey);
    return new BigNumber(balance / LAMPORTS_PER_SOL).decimalPlaces(9).toString();
  }

  async getTokenBalance(tokenAddress) {
    const pubKey = new PublicKey(this.address);
    const tokenPubKey = new PublicKey(tokenAddress);

    try {
      const tokenAccount = await getAssociatedTokenAddress(tokenPubKey, pubKey);
      const balance = await this.connection.getTokenAccountBalance(tokenAccount, 'confirmed');
      return balance.value.uiAmountString || '0';
    } catch (err) {
      return '0';
    }
  }

  async depositTokenAmount(tokenAddress, amount) {
    const destination = getConfig('SOLANA_TO');
    const pubKey = new PublicKey(this.address);
    const tokenPubKey = new PublicKey(tokenAddress);
    const destinationPubKey = new PublicKey(destination);
    const sourceAccountAddress = await getAssociatedTokenAddress(tokenPubKey, pubKey);
    const sourceAccount = await this.connection.getParsedAccountInfo(sourceAccountAddress);
    const destinationAccountAddress = await getAssociatedTokenAddress(tokenPubKey, destinationPubKey);
    const destinationAccount = await this.connection.getAccountInfo(destinationAccountAddress);
    const decimals = sourceAccount.value?.data?.parsed?.info?.tokenAmount?.decimals || 9;
    const blockhash = await this.connection.getLatestBlockhash().then(res => res.blockhash);

    const instructions = [];

    if (!destinationAccount) {
      instructions.push(
        createAssociatedTokenAccountInstruction(
          pubKey,
          destinationAccountAddress,
          destinationPubKey,
          tokenPubKey
        )
      );
    }

    instructions.push(
      createTransferInstruction(
        sourceAccountAddress,
        destinationAccountAddress,
        pubKey,
        new BigNumber(amount).shiftedBy(decimals).toNumber()
      )
    );

    const message = new TransactionMessage({
      payerKey: pubKey,
      recentBlockhash: blockhash,
      instructions,
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);
    const resp = await this.provider.signAndSendTransaction(transaction, { skipPreflight: false, });
    return resp.signature;
  }

  checkChain() {
    if (!this.provider) {
      throw new Error('Phantom provider not set');
    }
  }

  async startTransaction(amount, _network) {
    const blockhash = await this.connection.getLatestBlockhash().then(res => res.blockhash);
    const pubKey = new PublicKey(this.address);
    const destination = getConfig('SOLANA_TO');

    if (typeof amount !== 'string' && typeof amount !== 'number') { throw new TypeError('Invalid amount'); }

    const instructions = [
      SystemProgram.transfer({
        fromPubkey: pubKey,
        toPubkey: new PublicKey(destination),
        lamports: amount * LAMPORTS_PER_SOL,
      }),
    ];

    const message = new TransactionMessage({
      payerKey: pubKey,
      recentBlockhash: blockhash,
      instructions,
    }).compileToV0Message();

    const transaction = new VersionedTransaction(message);
    const resp = await this.provider.signAndSendTransaction(transaction, { skipPreflight: false, });
    return resp.signature;
  }

  parseError(err) {
    if (!err.code) {
      return err;
    }

    switch (err.code) {
      case 4001:
        return new WalletError(
          'The connection request was rejected.',
          err.code
        );

      default:
        return new WalletError(err.message, err.code);
    }
  }
}
