<template>
  <ModalMain
    @close="close"
  >
    <div class="px-4 md:px-7 pt-4 w-full">
      <article class="animate-fade-in">
        <div class="text-center block">
          <h1 class="text-2xl tracking-wide font-semibold mb-2 mt-5 text-center animate-slide-down-fade-in01s">New version of website is available</h1>
          <div class="text-slate-100 mt-4 leading-tight animate-slide-down-fade-in04s text-center">
            <p>Please reload the page now</p>
          </div>
          <ButtonButton
            type="button"
            class="block w-full mt-12"
            @click.prevent.stop="reload()"
          >
            Reload now
          </ButtonButton>
        </div>
      </article>
    </div>
  </ModalMain>
</template>

<script>
import { mapWritableState } from 'pinia';

export default defineComponent({
  name: 'ModalReloadMain',
  data() {
    return {};
  },
  computed: {
    ...mapWritableState(useUiStore, ['showReloadModal',]),
  },
  methods: {
    close() {
      this.showReloadModal = false;
    },
    reload() {
      window.location.reload(true);
    },
  },
});
</script>
