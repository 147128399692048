/*
Nuxt 3 recommends using $fetch instead of Axios: https://v3.nuxtjs.org/api/utils/$fetch
This custom plugin can be used in pages and in pinia stores via this.$nuxt.$api.get
*/
export default defineNuxtPlugin((nuxtApp) => {
  const authStore = useAuthStore();
  const {
    user,
  } = storeToRefs(authStore);

  const apiFetch = $fetch.create({
    baseURL: nuxtApp.$config.public.API_HOST,
    async onRequest({ options, }) {
      try {
        await authStore.getUser();
      } catch (err) {
        nuxtApp.$rollbar.error('$fetch.create::: getUser failed:', err);
      }

      if (user.value?.signInUserSession?.accessToken?.jwtToken) {
        options.headers = new Headers({
          Authorization: `Bearer ${user.value?.signInUserSession?.accessToken?.jwtToken}`,
        });
      }
    },
    async onResponseError({ response, }) {
      if (response.status === 401) {
        await authStore.getUser();
      }
    },
  });

  return {
    provide: {
      api: async(url, payload) => {
        try {
          const res = await apiFetch(url, payload);
          return res;
        } catch (err) {
          if (err.data?.status === 401 && err.data?.message !== 'You must be a VIP to access this data.') {
            return authStore.logout();
          }
          // nuxtApp.$rollbar.error('$fetch.create::: api failed:', err);
          console.error(err);
          throw err;
        }
      },
    },
  };
});
