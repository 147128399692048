import BigNumber from 'bignumber.js';

import { groupBy } from '@/utils/groupBy';

export const useMinigamesStore = defineStore({
  id: 'minigames-store',
  state: () => {
    return {
      showGameContainer: false,
      gameSelected: null,
      entryData: null,
      activityUpdatePayload: '',
      activityUpdateCount: 0,
      activityCountMax: 20, // controls how many activities load in the tab and the max number of activity updates
      tournaments: [],
      leaderboardSchedules: [],
    };
  },
  actions: {
    async loadTournaments() {
      const nuxtApp = useNuxtApp();
      try {
        const data = await nuxtApp.$api('/tournament', { method: 'GET', });
        const dateTimeNow = Math.ceil(new Date().getTime() / 1000);

        const noGroup = data.filter(t => t.public && !t.group);
        const hasGroup = data.filter(t => t.public && t.group);
        const allInternal = data.filter(t => !t.public);

        const templateGrouped = Object.values(groupBy(noGroup, 'templateId')).map(group => group[0]);
        const grouped = Object.values(groupBy(hasGroup, 'group')).map(group => group[0]);

        const tournaments = templateGrouped.concat(grouped).concat(allInternal);

        let sortedTournaments = tournaments
        .map(el => ({
          ...el,
          secondsToStart: Math.ceil(new Date(el.startTime).getTime() / 1000),
          secondsToEnd: Math.ceil(new Date(el.endTime).getTime() / 1000),
        }))
        .filter(el => (el.allowJoinAfterStart && (!el.secondsToEnd || (el.secondsToEnd - dateTimeNow) > 0)) || (!el.allowJoinAfterStart && (el.playerJoined || ((el.secondsToStart - dateTimeNow) > 0))));

        sortedTournaments = _OrderBy(sortedTournaments, ['displayPriority', 'id',], ['desc', 'desc',]);

        this.tournaments = sortedTournaments;
      } catch (err) {
        nuxtApp.$rollbar.error('method failed', err);
      }
    },
    async loadLeaderboardSchedules() {
      const data = await useNuxtApp().$api('/leaderboard/schedule', { method: 'GET', });
      for (const schedule of data) {
        schedule.startTime = new Date(schedule.startTime);
        schedule.endTime = new Date(schedule.endTime);
      }
      this.leaderboardSchedules = _OrderBy(data, ['endTime',], ['asc',]);
    },
    async launchGame(game, preview = false, displayMode = 'floating') {
      const nuxtApp = useNuxtApp();
      const bankingStore = useBankingStore();
      const uiStore = useUiStore();

      try {
        // Bit of a hack for now, it'll have to do for the time being.
        const tournament = this.findTournamentByGame(game);
        this.gameSelected = tournament || game;
        bankingStore.activeCurrencyOverride = bankingStore.activeCurrency;
        bankingStore.activeContextOverride = bankingStore.activeContext;

        if (preview) {
          this.entryData = await nuxtApp.$api(`/tournament/${tournament.id}/preview`, { method: 'GET', });
        } else {
          this.entryData = await nuxtApp.$api(`/tournament/${tournament.id}/entry`, {
            method: 'POST',
            body: {
              currencyCode: bankingStore.currentCurrency,
              fundsContext: bankingStore.currentContext,
            },
          });
        }

        const isTournamentSelected = !!tournament;
        const isFullScreenOnly = isTournamentSelected ? this.gameSelected.game?.metadata?.fullscreenOnly : this.gameSelected.metadata?.fullscreenOnly;
        const slug = isTournamentSelected ? this.gameSelected.game?.slug : this.gameSelected.slug;

        if (displayMode === 'floating' && isFullScreenOnly && slug) {
          await navigateTo(`/minigames/${slug}`);
          this.closeGame(); // close minigame container when navigating to fullscreen game
          return;
        }

        if (displayMode === 'floating') {
          this.showGameContainer = true;
        }
      } catch (error) {
        const errorBody = error.response._data;
        if (errorBody.name === 'DepositRequired') {
          uiStore.errorModalType = 'Free';
          uiStore.errorModalMessage = `Please deposit at least <span class="text-white">${errorBody.info.requiredDepositAmount} ETH</span>${errorBody.info.hours ? ', within the last ' + errorBody.info.hours + ' hours,' : ''} to enter this game. `;

          const requiredAmount = new BigNumber(errorBody.info.requiredDepositAmount);
          const currentAmount = new BigNumber(errorBody.info.totalDepositedInPeriod);

          if (currentAmount.isGreaterThan(0)) { uiStore.errorModalMessage += `You need to deposit <span class="text-white">${requiredAmount.minus(currentAmount).toNumber()} ETH</span> more.`; }
        } else {
          uiStore.errorModalType = 'MinigameNotFound';
        }
        uiStore.showErrorModal = true;
      }
    },
    async openGameDirect(gameData, preview, displayMode) {
      if (!gameData) { return; }
      await this.loadTournaments();
      await this.launchGame(gameData, preview, displayMode);
      return true;
    },
    closeGame() {
      const bankingStore = useBankingStore();
      bankingStore.enableBalanceUpdate(true);
      this.showGameContainer = false;
      this.gameSelected = null;
      this.entryData = null;
      bankingStore.activeCurrencyOverride = null;
      bankingStore.activeContextOverride = null;
    },
    findTournamentByGame(game) {
      // By tournament game ID
      let tournament = this.tournaments.find(el => el.game.id === game?.id);

      // By tournament ID
      if (!tournament) { tournament = this.tournaments.find(el => el.id === game?.id); }

      // By tournament name
      if (!tournament) { tournament = this.tournaments.find(el => el?.name === game?.name); }

      // By tournament game name
      if (!tournament) { tournament = this.tournaments.find(el => el?.game.name === game?.name); }

      return tournament;
    },
  },
});
